import apiRest from "../helpers/apiRest/apiRest"
import axios from "axios"
import {SettingType} from "../_types/setting";

const UserSettingsApiUrl = '/lp/unsubscribe/user_settings'
const SaveUserSettingsApiUrl = '/lp/unsubscribe/user_settings/save'

export type GetUserSettingsType = {
  uid: string
  cid: string
}

export type SaveUserSettingsType = {
  uid: string
  cid: string
  settings: SettingType[]
}

export const getUserSettingsApi = async (params: GetUserSettingsType) => {
  try {
    const url = `${UserSettingsApiUrl}/${params.uid}/${params.cid}`
    const response = await apiRest().get(url, { headers: { from: 'dashboard' }})
    const { data } = response
    return data
  } catch (error:any) {
      if (axios.isAxiosError(error)) {
        if (error?.response?.data) {
          return error.response.data
        }
        return {
          code: error.code,
          fail: error.message,
        }
      }
      return error
    }
};

export const saveUserSettingsApi = async (params: SaveUserSettingsType) => {
  try {
    const response = await apiRest().post(
      SaveUserSettingsApiUrl, {...params}, { headers: { from: 'dashboard' }}
    )
    const { data } = response
    return data
  } catch (error:any) {
      if (axios.isAxiosError(error)) {
        return {
          failed: true,
          code: error.code,
          message: error.message,
        }
      }
      return error
    }
};
