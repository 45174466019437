class WebStorage {
  static getValue(key: string, defaultValue: any = null) {
    const data = localStorage.getItem(key)
    if (data === null || data === undefined) {
      return defaultValue
    }
    try {
      return JSON.parse(localStorage.getItem(key) as string)
    } catch (e) {
      return null
    }
  }

  static setValue(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value))
  }

  static removeKey(key: string) {
    localStorage.removeItem(key)
  }
}

export default WebStorage
