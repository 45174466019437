import React, {createContext, useState, ReactNode} from 'react';

export type AlertType = 'error' | 'message'

interface IMessageContext {
  message: string
  displayed: boolean
  type: AlertType
  displayMessage: (message: string, type?: AlertType, duration?: number) => void
}

export const AlertContext = createContext<IMessageContext>({
  displayed: false,
  message: '',
  type: 'message',
  // noinspection JSUnusedLocalSymbols
  displayMessage: (newMessage: string, type?: AlertType, duration?: number) => {}
});

interface MessageProviderProps {
  children: ReactNode;
}

export const AlertProvider: React.FC<MessageProviderProps> = ({children}) => {
  const [message, setMessage] = useState<string>('');
  const [displayed, setDisplayed] = useState(false)
  const [type, setType] = useState<AlertType>('message')

  const displayMessage = (newMessage: string, type: AlertType = 'message', duration: number = 5000) => {
    setType(type)
    setMessage(newMessage)
    setDisplayed(true)
    setTimeout(() => {
      setDisplayed(false)
      setMessage('')
    }, duration)
  }

  return (
    <AlertContext.Provider value={{message, displayed, type, displayMessage}}>
      {children}
    </AlertContext.Provider>
  );
};

