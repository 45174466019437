import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import {DateTime, DateTimeFormatOptions} from 'luxon'
import { layout as layoutEn} from './locales/en/layout'
import { layout as layoutFr} from './locales/fr/layout'
import { error as errorFr} from './locales/fr/error'
import { error as errorEn} from './locales/en/error'
import { settings as settingsFr} from './locales/fr/settings'
import { settings as settingsEn} from './locales/en/settings'

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: 'en',
    interpolation: {
      format: (value: any, format: keyof typeof DateTime, lng: string): string => {
        if (value instanceof Date) {
          return DateTime.fromJSDate(value).setLocale(lng).toLocaleString(DateTime[format] as DateTimeFormatOptions)
        }
        return value;
      }
    },
    resources: {
      en: {
        layout: layoutEn,
        error: errorEn,
        settings: settingsEn
      },
      fr: {
        layout: layoutFr,
        error: errorFr,
        settings: settingsFr
      }
    }
  })
  .then()

export default i18n
