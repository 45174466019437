import {FC, createContext, ReactNode} from 'react'

import {
  getUserSettingsApi, GetUserSettingsType,
  saveUserSettingsApi , SaveUserSettingsType
} from "../_api/sessionApi"

interface Props {
  children: ReactNode
}

export const SessionContext = createContext<any>({})

const SessionProvider: FC<Props> = ({ children}) => {

  const getUserSettings = async (params:GetUserSettingsType) => {
    return await getUserSettingsApi(params)
  }

  const saveUserSettings = async (params:SaveUserSettingsType) => {
    return await saveUserSettingsApi(params)
  }

  const context = {
    getUserSettings, saveUserSettings,
  }

  return (
    <SessionContext.Provider value={context}>
      {children}
    </SessionContext.Provider>
  )
}

export default SessionProvider
