export const settings = {
  title: 'Bienvenue sur la gestion des e-mails reçus de {{appName}}',
  subTitle: 'Notifications pa e-mail que vous recevez :',
  categoryEvent: 'Événements',
  categoryGroup: 'Sous-communauté',
  categoryActuality: 'Actualités',

  notificationEmailNewEvent: 'Annonce d\'un nouvel événement',
  notificationEmailNewEventReminders: 'Rappels des événements',
  notificationEmailNewActuTarget: 'Mise en avant de votre profil dans une actualité de la communauté',
  notificationEmailNewAgoraTarget: 'Mise en avant de votre profil dans une actualité des membres',

  notificationEmailNewInvitation: 'Invitation dans une sous-communauté',
  notificationEmailMembershipUpdate: 'Acceptation d\'une demande d\'adhésion à une sous-communauté ou événement',
  notificationEmailNewMessage: 'Réception d\'un nouveau message',

}
