import {createBrowserRouter, RouterProvider} from 'react-router-dom'
import {FC, useContext} from 'react'
import {Grommet, Page, PageContent} from 'grommet'

import {appRoutes} from './AppRoutes'
import {AlertContext} from '../contexts/Alert'

import {theme} from '../theme'
import {CustomizationContext} from '../contexts/Customization'
import AlertBox from '../components/layout/AlertBox'

const Layout: FC = () => {
  const router = createBrowserRouter(appRoutes)
  const {darkMode} = useContext(CustomizationContext)
  const {message, displayed, type} = useContext(AlertContext)

  return (
    <Grommet theme={theme} full themeMode={darkMode ? "dark" : "light"}>
      <Page>
        <PageContent width="100%">
          <RouterProvider router={router}/>
        </PageContent>
      </Page>
      {displayed && <AlertBox message={message} type={type}/>}
    </Grommet>
  )
}

export default Layout
