import {FC, createContext, useState, ReactNode} from 'react'

interface Customization_Context {
  darkMode: boolean
  setDarkMode: any
}

interface Props {
  children: ReactNode
}

export const CustomizationContext = createContext<Customization_Context>({
  darkMode: false, setDarkMode: null
})

const CustomizationProvider: FC<Props> = ({ children}) => {
  const [darkMode, setDarkMode] = useState(false)

  const context = {
    darkMode, setDarkMode
  }

  return (
    <CustomizationContext.Provider value={context}>
      {children}
    </CustomizationContext.Provider>
  )
}

export default CustomizationProvider
