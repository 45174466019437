import {FC, Suspense} from "react"

import SessionProvider from "./contexts/Session";
import Layout from "./pages/Layout";
import './i18n/i18n'
import CustomizationProvider from "./contexts/Customization";
import {AlertProvider} from "./contexts/Alert";

const App: FC = () => {

  return (
    <CustomizationProvider>
      <SessionProvider>
        <AlertProvider>
          <Layout/>
        </AlertProvider>
      </SessionProvider>
    </CustomizationProvider>
  )
}

export default function WrappedApp() {
  return (
    <Suspense fallback="...is loading">
      <App/>
    </Suspense>
  )
};
