import React, {useContext, useState, useEffect} from "react"
import {FormExtendedEvent} from "grommet"
import {useLocation} from "react-router-dom";
import {SettingType} from '../../_types/setting';
import Settings from "./Settings"
import {SessionContext} from "../../contexts/Session"
import {AlertContext} from "../../contexts/Alert"
import {useTranslation} from "react-i18next";

const SettingsContainer = () => {
  const query = new URLSearchParams(useLocation().search);
  const uid = query.get('uid')
  const cid = query.get('cid')

  const { t } = useTranslation(['error', 'settings'])

  const [settings, setSettings] = useState<SettingType[]>([])
  const [appName, setAppName] = useState('')
  const [logo, setLogo] = useState('')

  const { getUserSettings, saveUserSettings } = useContext(SessionContext)
  const { displayMessage } = useContext(AlertContext)

  useEffect(() => {
    getUserSettings({uid, cid})
    .then((result:any) => {
      if (result?.content?.settings) {
        setAppName(result.content.appName)
        setLogo(result.content.communityLogo)
        const newUserSettings = result.content.settings.data.user.map((item:any) => (
          { zone: 'user', key: item.key, value: item.value }
        ))
        const newCommunitySettings = result.content.settings.data.community.map((item:any) => (
          { zone: 'community', key: item.key, value: item.value }
        ))
        setSettings([...newUserSettings, ...newCommunitySettings])
      } else {
        displayMessage(result.fail, 'error', 5000)
      }
    })
    .catch((err:any) => {
      displayMessage(err.fail, 'error', 5000)
    })
  }, []);

  const submit = async (event:FormExtendedEvent) => {
    saveUserSettings({uid, cid, settings})
      .then((result: any) => {
        if (result?.failed) {
          displayMessage(t('error:GENERIC_ERROR', 'error', 5000))
        } else {
          displayMessage(t('settings:updateSuccessMessage', 'message', 5000))
        }
      })
      .catch(() => {
        displayMessage(t('error:GENERIC_ERROR', 'error', 5000))
      })
  }

  return (
    <div>
      {(uid === null || cid === null)
        ? <div />
        :
          <Settings
            submit={submit}
            settings={settings}
            update={(settings:SettingType[]) => setSettings(settings)}
            appName={appName}
            logo={logo}
          />
      }
    </div>
  )
}

export default SettingsContainer
