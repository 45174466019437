import { grommet } from "grommet"
import { deepMerge} from "grommet/utils"

export const theme = deepMerge(grommet, {
  global: {
    colors: {
      white: "#FFFFFF",
      primary: "#4DAD5A"
    },
    spacing: "12px",
    font: {
      family: "Montserrat",
      size: "18px",
      height: "20px",
    },
  },
  checkBox: {
    size: '20px',
    toggle: {
      background: "#d0d0d0",
      color: '#707070',
    },
    border: {
      color: '#FFFFFF'
    },
    color: '#4DAD5A',
    background: '#4DAD5A'
  }
})
