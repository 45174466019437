import {Box, Layer} from 'grommet'
import React from 'react'
import {AlertType} from '../../contexts/Alert'

type PropsType = {
  message: string
  type: AlertType
}
const AlertBox = (props: PropsType) => {
  const {message, type} = props

  return (
    <Layer position="bottom" modal={false} responsive={true}>
      <Box
        align="center"
        justify="center"
        background={type === 'error' ? 'red' : 'lightgreen'}
        pad="medium"
        round="small"
        elevation="medium"
      >
        {message}
      </Box>
    </Layer>
  )
}

export default AlertBox
