import WebStorage from "../webStorage/WebStorage";
import axios from 'axios'
import { sha256 } from 'js-sha256'

const BASE_URL:string = process.env.REACT_APP_BASE_URL ?? ''

function apiRest(isSecurity = false) {
  const currentToken = WebStorage.getValue('token')
  const selectedCommunity = WebStorage.getValue('selectedCommunity')
  const whiteLabelName = selectedCommunity?.whiteLabelName || null
  const communityName = selectedCommunity?.name

  const api = isSecurity
    ? axios.create({
        baseURL: BASE_URL,
        timeout: 900000,
        headers: {
          'Content-Type': 'application/json; charset=utf-8',
          from: 'dashboard',
        },
      })
    : axios.create({
        baseURL: BASE_URL,
        timeout: 900000,
        headers: {
          'auth-token': currentToken,
          'Content-Type': 'application/json; charset=utf-8',
          'white-label': true,
          'community-name': whiteLabelName || communityName || 'superconnectr',
          from: 'dashboard',
        },
      })

  api.interceptors.request.use(
    (config) => {
      let data
      const { url } = config
      switch (config.method) {
        case 'get':
          data = encodeURI(url?.trim() ?? '')
          break
        default:
          data = JSON.stringify(config.data)
          break
      }
      const newConfig = config
      newConfig.headers['Validation-Code'] = sha256(data + process.env.REACT_APP_SYSTEM_API_KEY)

      return newConfig
    },
    (error) => Promise.reject(error)
  )

  return api
}

export default apiRest
