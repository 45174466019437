export const layout = {
  // generic words
  save: 'enregistrer',
  welcome: 'bienvenue',

  // topBar
  toDarkMode: 'Basculer l\'interface en mode sombre',
  toLightMode: 'Basculer l\'interface en mode clair',


}
