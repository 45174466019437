export const settings = {
  title: 'Gérer mes e-mails reçus de {{appName}}',
  subTitle: 'Notifications par e-mail que vous recevez :',
  categoryEvent: 'Événements',
  categoryGroup: 'Sous-communauté',
  categoryActuality: 'Actualités',

  notificationEmailNewEvent: 'Annonce d\'un nouvel événement',
  notificationEmailNewEventReminders: 'Rappels des événements (j-7 et j-1)',
  notificationEmailNewActuTarget: 'Mise en avant de votre profil dans une actualité de la communauté',
  notificationEmailNewAgoraTarget: 'Mise en avant de votre profil dans une actualité publié par un membre',

  notificationEmailNewInvitation: 'Invitation dans une sous-communauté ou un événement',
  notificationEmailMembershipUpdate: 'Confirmation pour rejoindre une sous-communauté ou un événement',
  notificationEmailNewMessage: 'Réception d\'un nouveau message',

  updateSuccessMessage: 'Vos modifications ont bien été prises en compte.'
}
