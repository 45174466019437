import {Box, Button, CheckBox, Form, FormExtendedEvent, Heading, Image, Text} from "grommet"
import {useTranslation} from "react-i18next"
import {SettingType} from '../../_types/setting';
import {useEffect, useState} from "react";

type SubmitType = (event: FormExtendedEvent) => void
type UpdateType = (settings: SettingType[]) => void

type SignInProps = {
  appName: string
  logo: string
  settings: SettingType[]
  submit: SubmitType
  update: UpdateType
}

const Settings = (props:SignInProps) => {
  const {logo, appName, settings, submit, update} = props
  const { t } = useTranslation(['layout', 'settings'])
  const [checkBoxes, setCheckBoxes] = useState(settings)

  const handleChange = (data:any) => {
    const newStates = checkBoxes.map((item) => {
      if (item.key === data.target.id) {
        return { key: item.key, value: data.target.checked, zone: item.zone }
      } else {
        return item
      }
    })
    setCheckBoxes(newStates)
    update(newStates)
  }

  useEffect(() => {
    setCheckBoxes(settings);
  }, [settings])

  if (settings.length === 0) {
    return null;
  }

  return (
    <Box align="center" margin="small" >
      <Box align="start" margin="small">
        <Box direction="row" align="center" >
          <Box width="xsmall">
            <Image fit="contain" src={logo} />
          </Box>
          <Box>
            <Text style={{fontSize: 26, fontWeight: 600}}>{t('settings:title', {appName})}</Text>
          </Box>
        </Box>
        <Box align="center" justify="center" width="large">
          <Box width="xlarge" margin="small">
            <Form onSubmit={submit}>
              <Box pad="small" alignSelf="center">
                <Text style={{ fontSize: 24, fontWeight: 500 }}>{t('settings:subTitle')}</Text>
              </Box>
              <Box align="center" direction="column">
                {checkBoxes.map((item => (
                  <Box key={item.key} pad="small" direction="row" justify="between" width="100%" margin="none">
                    <Box style={{ fontSize: 20, fontWeight: 400 }}>
                      <Text >
                        {t(`settings:${item.key}`)}
                      </Text>
                    </Box>
                    <CheckBox checked={item.value} id={item.key} toggle reverse onChange={handleChange} />
                  </Box>
                )))}
              </Box>
              <Box align="end">
                <Button
                  style={{ color: '#FFFFFF', fontSize: 16, fontWeight: 500}}
                  type="submit"
                  label={t('layout:save').toUpperCase()}
                  primary
                  color="primary"
                  margin="small"
                />
              </Box>
            </Form>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default Settings
